@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wdth,wght@0,87.7,273;1,87.7,273&display=swap');

/* FONT STYLING */
.roboto-regular {
    font-family: 'Roboto', sans-serif;
    font-weight: 400; /* Regular weight */
}

.roboto-light {
    font-family: 'Roboto', sans-serif;
    font-weight: 100; /* Light weight */
}

.roboto-narrow {
    font-family: 'Roboto', sans-serif;
    font-variation-settings: 'wdth' 87.7, 'wght' 273; /* Narrow style */
}


/* Thumbnail Component Styles */
.thumbnail-container {
  position: relative;
  width: 300px;
  height: 200px;
  margin: 10px;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.5s;
}

.thumbnail-container:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.thumbnail-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #fff;
  opacity: 0;
  transition: opacity 0.5s;
}

.thumbnail-container:hover .thumbnail-overlay {
  opacity: 1;
}

.thumbnail-title {
  font-size: 18px;
  font-weight: lighter;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* GENERAL STYLING: */

body {
  background-color: #121212;
  color: #ffffff;
  font-family: 'Roboto';
  margin: 0;
  padding: 0;
}

.header {
  background: linear-gradient(45deg, #000428, #004e92);
  text-align: center;
  padding: 0px;
  max-height: 200px;
  color: white;
  
}

.header-title {
  font-size: 3rem;
  font-weight: bold;
  font-family: 'Roboto';
  animation: pulse 3s infinite;
}

.nav-bar{
  background-color: #333;
  width: 100%;
  max-height: 60px;
  display: flex;
  justify-content: center;
}

.header-subtitle {
  font-size: 1.5rem;
  margin-bottom: 20px;
  font-family: 'Roboto', ;
}

.nav-links {
  display:flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: -10px;
}

.nav-link {
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.3s, text-shadow 0.3s;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.nav-link:hover {
  transform: scale(1.1);
  text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.7);
}

.view-header {

  position:relative;
  text-align: center;
  margin: 0px 0;
  border-radius: 10px;
  /* background-color: #0056b3; */
  /* max-width: 30%; */
}

.view-title {
  font-size: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 100; /* Light weight */
  color: #ffffff;
  border-radius: 0px;
}

.gallery {
  place-items:center;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  padding: 20px;
  border-radius: 10px;
  background-color: whitesmoke;
  width:90%;
}
.gallery-container {
  display:grid;
  place-items: center;
}

.thumbnail {
  position: relative;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s, box-shadow 0.3s;
}

.thumbnail:hover {
  transform: scale(1.05);
  box-shadow: 0 10px 20px rgba(255, 223, 0, 0.8);
}

.gallery:hover .thumbnail:not(:hover) {
  filter: blur(10%);
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  animation: fadeIn 0.5s;
}

.modal-content {
  background: #332;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
}

.modalHeader {
  display: flex;
  justify-content: space-between; /* Ensures close button is on the right */
  align-items: center;
  padding: 10px;
  background: #332;
  border-radius: 5px;
  height: auto; /* Dynamically adjusts height for content */
}

.modal-close {
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px; /* Adjust padding as needed */
  cursor: pointer;
  transition: background 0.3s;
  position: relative; /* Set relative if not overlapping */
}

.modal-close:hover {
  background: #0056b3;
}


/* Mobile styles */
@media (max-width: 767px) {
  .thumbnail-container {
    width: 150px;
    height: 100px;
    margin: 5px;
  }

  .thumbnail-overlay {
    opacity: 1 !important; /* Always visible */
  }

  .thumbnail-title {
    font-size: 10px;
    font-weight: bold;
  }

  .header-title {
    font-size: 2rem;
  }

  .nav-bar {
    /* flex-direction: column; */
    gap: 15px;
  }

  .nav-links {
    /* flex-direction: column; */
    height: 20px;
    padding: 14px;
    gap: 2px;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .nav-link {
    font-size: 0.7rem;
    gap: 10% 0;
    margin: 3px 0;
  }

  .view-header {
    text-align: center;
    margin: 10px 0;
  }

  .gallery {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}


@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
